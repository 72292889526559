/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var floors_pb = require('./floors_pb.js')

var desks_pb = require('./desks_pb.js')

var bookings_pb = require('./bookings_pb.js')

var parking_areas_pb = require('./parking_areas_pb.js')

var parking_bookings_pb = require('./parking_bookings_pb.js')

var members_pb = require('./members_pb.js')

var google_api_annotations_pb = require('./google/api/annotations_pb.js')
const proto = require('./services_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.BookItClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.BookItPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListFloorsRequest,
 *   !proto.ListFloorsResponse>}
 */
const methodDescriptor_BookIt_ListFloors = new grpc.web.MethodDescriptor(
  '/BookIt/ListFloors',
  grpc.web.MethodType.UNARY,
  floors_pb.ListFloorsRequest,
  floors_pb.ListFloorsResponse,
  /**
   * @param {!proto.ListFloorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  floors_pb.ListFloorsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ListFloorsRequest,
 *   !proto.ListFloorsResponse>}
 */
const methodInfo_BookIt_ListFloors = new grpc.web.AbstractClientBase.MethodInfo(
  floors_pb.ListFloorsResponse,
  /**
   * @param {!proto.ListFloorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  floors_pb.ListFloorsResponse.deserializeBinary
);


/**
 * @param {!proto.ListFloorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ListFloorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListFloorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.listFloors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/ListFloors',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListFloors,
      callback);
};


/**
 * @param {!proto.ListFloorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListFloorsResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.listFloors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/ListFloors',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListFloors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListDesksRequest,
 *   !proto.ListDesksResponse>}
 */
const methodDescriptor_BookIt_ListDesks = new grpc.web.MethodDescriptor(
  '/BookIt/ListDesks',
  grpc.web.MethodType.UNARY,
  desks_pb.ListDesksRequest,
  desks_pb.ListDesksResponse,
  /**
   * @param {!proto.ListDesksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  desks_pb.ListDesksResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ListDesksRequest,
 *   !proto.ListDesksResponse>}
 */
const methodInfo_BookIt_ListDesks = new grpc.web.AbstractClientBase.MethodInfo(
  desks_pb.ListDesksResponse,
  /**
   * @param {!proto.ListDesksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  desks_pb.ListDesksResponse.deserializeBinary
);


/**
 * @param {!proto.ListDesksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ListDesksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListDesksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.listDesks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/ListDesks',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListDesks,
      callback);
};


/**
 * @param {!proto.ListDesksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListDesksResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.listDesks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/ListDesks',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListDesks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SaveDesksRequest,
 *   !proto.SaveDesksResponse>}
 */
const methodDescriptor_BookIt_SaveDesks = new grpc.web.MethodDescriptor(
  '/BookIt/SaveDesks',
  grpc.web.MethodType.UNARY,
  desks_pb.SaveDesksRequest,
  desks_pb.SaveDesksResponse,
  /**
   * @param {!proto.SaveDesksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  desks_pb.SaveDesksResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SaveDesksRequest,
 *   !proto.SaveDesksResponse>}
 */
const methodInfo_BookIt_SaveDesks = new grpc.web.AbstractClientBase.MethodInfo(
  desks_pb.SaveDesksResponse,
  /**
   * @param {!proto.SaveDesksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  desks_pb.SaveDesksResponse.deserializeBinary
);


/**
 * @param {!proto.SaveDesksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SaveDesksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SaveDesksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.saveDesks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/SaveDesks',
      request,
      metadata || {},
      methodDescriptor_BookIt_SaveDesks,
      callback);
};


/**
 * @param {!proto.SaveDesksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SaveDesksResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.saveDesks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/SaveDesks',
      request,
      metadata || {},
      methodDescriptor_BookIt_SaveDesks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.DeleteDesksRequest,
 *   !proto.DeleteDesksResponse>}
 */
const methodDescriptor_BookIt_DeleteDesks = new grpc.web.MethodDescriptor(
  '/BookIt/DeleteDesks',
  grpc.web.MethodType.UNARY,
  desks_pb.DeleteDesksRequest,
  desks_pb.DeleteDesksResponse,
  /**
   * @param {!proto.DeleteDesksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  desks_pb.DeleteDesksResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.DeleteDesksRequest,
 *   !proto.DeleteDesksResponse>}
 */
const methodInfo_BookIt_DeleteDesks = new grpc.web.AbstractClientBase.MethodInfo(
  desks_pb.DeleteDesksResponse,
  /**
   * @param {!proto.DeleteDesksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  desks_pb.DeleteDesksResponse.deserializeBinary
);


/**
 * @param {!proto.DeleteDesksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.DeleteDesksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.DeleteDesksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.deleteDesks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/DeleteDesks',
      request,
      metadata || {},
      methodDescriptor_BookIt_DeleteDesks,
      callback);
};


/**
 * @param {!proto.DeleteDesksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.DeleteDesksResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.deleteDesks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/DeleteDesks',
      request,
      metadata || {},
      methodDescriptor_BookIt_DeleteDesks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListBookingsRequest,
 *   !proto.ListBookingsResponse>}
 */
const methodDescriptor_BookIt_ListBookings = new grpc.web.MethodDescriptor(
  '/BookIt/ListBookings',
  grpc.web.MethodType.UNARY,
  bookings_pb.ListBookingsRequest,
  bookings_pb.ListBookingsResponse,
  /**
   * @param {!proto.ListBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.ListBookingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ListBookingsRequest,
 *   !proto.ListBookingsResponse>}
 */
const methodInfo_BookIt_ListBookings = new grpc.web.AbstractClientBase.MethodInfo(
  bookings_pb.ListBookingsResponse,
  /**
   * @param {!proto.ListBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.ListBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.ListBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ListBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.listBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/ListBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListBookings,
      callback);
};


/**
 * @param {!proto.ListBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.listBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/ListBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SaveBookingsRequest,
 *   !proto.SaveBookingsResponse>}
 */
const methodDescriptor_BookIt_SaveBookings = new grpc.web.MethodDescriptor(
  '/BookIt/SaveBookings',
  grpc.web.MethodType.UNARY,
  bookings_pb.SaveBookingsRequest,
  bookings_pb.SaveBookingsResponse,
  /**
   * @param {!proto.SaveBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.SaveBookingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SaveBookingsRequest,
 *   !proto.SaveBookingsResponse>}
 */
const methodInfo_BookIt_SaveBookings = new grpc.web.AbstractClientBase.MethodInfo(
  bookings_pb.SaveBookingsResponse,
  /**
   * @param {!proto.SaveBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.SaveBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.SaveBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SaveBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SaveBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.saveBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/SaveBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_SaveBookings,
      callback);
};


/**
 * @param {!proto.SaveBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SaveBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.saveBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/SaveBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_SaveBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.DeleteBookingsRequest,
 *   !proto.DeleteBookingsResponse>}
 */
const methodDescriptor_BookIt_DeleteBookings = new grpc.web.MethodDescriptor(
  '/BookIt/DeleteBookings',
  grpc.web.MethodType.UNARY,
  bookings_pb.DeleteBookingsRequest,
  bookings_pb.DeleteBookingsResponse,
  /**
   * @param {!proto.DeleteBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.DeleteBookingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.DeleteBookingsRequest,
 *   !proto.DeleteBookingsResponse>}
 */
const methodInfo_BookIt_DeleteBookings = new grpc.web.AbstractClientBase.MethodInfo(
  bookings_pb.DeleteBookingsResponse,
  /**
   * @param {!proto.DeleteBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.DeleteBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.DeleteBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.DeleteBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.DeleteBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.deleteBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/DeleteBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_DeleteBookings,
      callback);
};


/**
 * @param {!proto.DeleteBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.DeleteBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.deleteBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/DeleteBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_DeleteBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetBookingCountRequest,
 *   !proto.GetBookingCountResponse>}
 */
const methodDescriptor_BookIt_GetBookingCount = new grpc.web.MethodDescriptor(
  '/BookIt/GetBookingCount',
  grpc.web.MethodType.UNARY,
  bookings_pb.GetBookingCountRequest,
  bookings_pb.GetBookingCountResponse,
  /**
   * @param {!proto.GetBookingCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.GetBookingCountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetBookingCountRequest,
 *   !proto.GetBookingCountResponse>}
 */
const methodInfo_BookIt_GetBookingCount = new grpc.web.AbstractClientBase.MethodInfo(
  bookings_pb.GetBookingCountResponse,
  /**
   * @param {!proto.GetBookingCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.GetBookingCountResponse.deserializeBinary
);


/**
 * @param {!proto.GetBookingCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetBookingCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetBookingCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.getBookingCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/GetBookingCount',
      request,
      metadata || {},
      methodDescriptor_BookIt_GetBookingCount,
      callback);
};


/**
 * @param {!proto.GetBookingCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetBookingCountResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.getBookingCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/GetBookingCount',
      request,
      metadata || {},
      methodDescriptor_BookIt_GetBookingCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetBookingRangeRequest,
 *   !proto.GetBookingRangeResponse>}
 */
const methodDescriptor_BookIt_GetBookingRange = new grpc.web.MethodDescriptor(
  '/BookIt/GetBookingRange',
  grpc.web.MethodType.UNARY,
  bookings_pb.GetBookingRangeRequest,
  bookings_pb.GetBookingRangeResponse,
  /**
   * @param {!proto.GetBookingRangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.GetBookingRangeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetBookingRangeRequest,
 *   !proto.GetBookingRangeResponse>}
 */
const methodInfo_BookIt_GetBookingRange = new grpc.web.AbstractClientBase.MethodInfo(
  bookings_pb.GetBookingRangeResponse,
  /**
   * @param {!proto.GetBookingRangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  bookings_pb.GetBookingRangeResponse.deserializeBinary
);


/**
 * @param {!proto.GetBookingRangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetBookingRangeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetBookingRangeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.getBookingRange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/GetBookingRange',
      request,
      metadata || {},
      methodDescriptor_BookIt_GetBookingRange,
      callback);
};


/**
 * @param {!proto.GetBookingRangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetBookingRangeResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.getBookingRange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/GetBookingRange',
      request,
      metadata || {},
      methodDescriptor_BookIt_GetBookingRange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListParkingAreasRequest,
 *   !proto.ListParkingAreasResponse>}
 */
const methodDescriptor_BookIt_ListParkingAreas = new grpc.web.MethodDescriptor(
  '/BookIt/ListParkingAreas',
  grpc.web.MethodType.UNARY,
  parking_areas_pb.ListParkingAreasRequest,
  parking_areas_pb.ListParkingAreasResponse,
  /**
   * @param {!proto.ListParkingAreasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  parking_areas_pb.ListParkingAreasResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ListParkingAreasRequest,
 *   !proto.ListParkingAreasResponse>}
 */
const methodInfo_BookIt_ListParkingAreas = new grpc.web.AbstractClientBase.MethodInfo(
  parking_areas_pb.ListParkingAreasResponse,
  /**
   * @param {!proto.ListParkingAreasRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  parking_areas_pb.ListParkingAreasResponse.deserializeBinary
);


/**
 * @param {!proto.ListParkingAreasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ListParkingAreasResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListParkingAreasResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.listParkingAreas =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/ListParkingAreas',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListParkingAreas,
      callback);
};


/**
 * @param {!proto.ListParkingAreasRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListParkingAreasResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.listParkingAreas =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/ListParkingAreas',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListParkingAreas);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListParkingBookingsRequest,
 *   !proto.ListParkingBookingsResponse>}
 */
const methodDescriptor_BookIt_ListParkingBookings = new grpc.web.MethodDescriptor(
  '/BookIt/ListParkingBookings',
  grpc.web.MethodType.UNARY,
  parking_bookings_pb.ListParkingBookingsRequest,
  parking_bookings_pb.ListParkingBookingsResponse,
  /**
   * @param {!proto.ListParkingBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  parking_bookings_pb.ListParkingBookingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ListParkingBookingsRequest,
 *   !proto.ListParkingBookingsResponse>}
 */
const methodInfo_BookIt_ListParkingBookings = new grpc.web.AbstractClientBase.MethodInfo(
  parking_bookings_pb.ListParkingBookingsResponse,
  /**
   * @param {!proto.ListParkingBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  parking_bookings_pb.ListParkingBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.ListParkingBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ListParkingBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListParkingBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.listParkingBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/ListParkingBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListParkingBookings,
      callback);
};


/**
 * @param {!proto.ListParkingBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListParkingBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.listParkingBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/ListParkingBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListParkingBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SaveParkingBookingsRequest,
 *   !proto.SaveParkingBookingsResponse>}
 */
const methodDescriptor_BookIt_SaveParkingBookings = new grpc.web.MethodDescriptor(
  '/BookIt/SaveParkingBookings',
  grpc.web.MethodType.UNARY,
  parking_bookings_pb.SaveParkingBookingsRequest,
  parking_bookings_pb.SaveParkingBookingsResponse,
  /**
   * @param {!proto.SaveParkingBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  parking_bookings_pb.SaveParkingBookingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SaveParkingBookingsRequest,
 *   !proto.SaveParkingBookingsResponse>}
 */
const methodInfo_BookIt_SaveParkingBookings = new grpc.web.AbstractClientBase.MethodInfo(
  parking_bookings_pb.SaveParkingBookingsResponse,
  /**
   * @param {!proto.SaveParkingBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  parking_bookings_pb.SaveParkingBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.SaveParkingBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SaveParkingBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SaveParkingBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.saveParkingBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/SaveParkingBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_SaveParkingBookings,
      callback);
};


/**
 * @param {!proto.SaveParkingBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SaveParkingBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.saveParkingBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/SaveParkingBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_SaveParkingBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.DeleteParkingBookingsRequest,
 *   !proto.DeleteParkingBookingsResponse>}
 */
const methodDescriptor_BookIt_DeleteParkingBookings = new grpc.web.MethodDescriptor(
  '/BookIt/DeleteParkingBookings',
  grpc.web.MethodType.UNARY,
  parking_bookings_pb.DeleteParkingBookingsRequest,
  parking_bookings_pb.DeleteParkingBookingsResponse,
  /**
   * @param {!proto.DeleteParkingBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  parking_bookings_pb.DeleteParkingBookingsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.DeleteParkingBookingsRequest,
 *   !proto.DeleteParkingBookingsResponse>}
 */
const methodInfo_BookIt_DeleteParkingBookings = new grpc.web.AbstractClientBase.MethodInfo(
  parking_bookings_pb.DeleteParkingBookingsResponse,
  /**
   * @param {!proto.DeleteParkingBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  parking_bookings_pb.DeleteParkingBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.DeleteParkingBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.DeleteParkingBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.DeleteParkingBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.deleteParkingBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/DeleteParkingBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_DeleteParkingBookings,
      callback);
};


/**
 * @param {!proto.DeleteParkingBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.DeleteParkingBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.deleteParkingBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/DeleteParkingBookings',
      request,
      metadata || {},
      methodDescriptor_BookIt_DeleteParkingBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GetMeRequest,
 *   !proto.GetMeResponse>}
 */
const methodDescriptor_BookIt_GetMe = new grpc.web.MethodDescriptor(
  '/BookIt/GetMe',
  grpc.web.MethodType.UNARY,
  members_pb.GetMeRequest,
  members_pb.GetMeResponse,
  /**
   * @param {!proto.GetMeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  members_pb.GetMeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GetMeRequest,
 *   !proto.GetMeResponse>}
 */
const methodInfo_BookIt_GetMe = new grpc.web.AbstractClientBase.MethodInfo(
  members_pb.GetMeResponse,
  /**
   * @param {!proto.GetMeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  members_pb.GetMeResponse.deserializeBinary
);


/**
 * @param {!proto.GetMeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GetMeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GetMeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.getMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/GetMe',
      request,
      metadata || {},
      methodDescriptor_BookIt_GetMe,
      callback);
};


/**
 * @param {!proto.GetMeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GetMeResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.getMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/GetMe',
      request,
      metadata || {},
      methodDescriptor_BookIt_GetMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListMembersRequest,
 *   !proto.ListMembersResponse>}
 */
const methodDescriptor_BookIt_ListMembers = new grpc.web.MethodDescriptor(
  '/BookIt/ListMembers',
  grpc.web.MethodType.UNARY,
  members_pb.ListMembersRequest,
  members_pb.ListMembersResponse,
  /**
   * @param {!proto.ListMembersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  members_pb.ListMembersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.ListMembersRequest,
 *   !proto.ListMembersResponse>}
 */
const methodInfo_BookIt_ListMembers = new grpc.web.AbstractClientBase.MethodInfo(
  members_pb.ListMembersResponse,
  /**
   * @param {!proto.ListMembersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  members_pb.ListMembersResponse.deserializeBinary
);


/**
 * @param {!proto.ListMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.ListMembersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListMembersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BookItClient.prototype.listMembers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BookIt/ListMembers',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListMembers,
      callback);
};


/**
 * @param {!proto.ListMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListMembersResponse>}
 *     Promise that resolves to the response
 */
proto.BookItPromiseClient.prototype.listMembers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BookIt/ListMembers',
      request,
      metadata || {},
      methodDescriptor_BookIt_ListMembers);
};


module.exports = proto;

