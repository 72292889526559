import { groupBy, keyBy, sortBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { createSuperContext } from "react-super-context";

import { useDates } from ".";
import { Booking, DeleteBookingsRequest, SaveBookingsRequest } from "../../Generated/bookings_pb";
import { Desk } from "../../Generated/desks_pb";
import { Day, Dictionary, messageFromObject } from "../Utilities";
import { useApiClient } from "./ApiClientContext";

const useGetBooking = (bookings: Booking.AsObject[]) => {
  const { days } = useDates();
  return useMemo(() => {
    const byDesk = groupBy(bookings, "deskId");
    const bookingsByDesk = Object.keys(byDesk).reduce<Dictionary<Booking.AsObject[]>>(
      (obj, deskId) => {
        const byDay = keyBy(byDesk[deskId], (booking) => booking.date?.seconds ?? 0);
        obj[deskId] = days.map((day) => byDay[day.dateAsTimestamp.seconds]);
        return obj;
      },
      {},
    );

    return (desk: Desk.AsObject | string | undefined, day: Day | number | undefined) => {
      if (desk === undefined || day === undefined) return undefined;
      const deskId = typeof desk === "string" ? desk : desk.id;
      const dayIndex = typeof day === "number" ? day : day.index;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Condition is necessary
      return bookingsByDesk?.[deskId]?.[dayIndex];
    };
  }, [bookings, days]);
};

const useGetBookingsByDay = (bookings: Booking.AsObject[]) => {
  const { days } = useDates();
  return useMemo(() => {
    const sorted = sortBy(bookings, (booking) => booking.member?.name);
    const byDay = groupBy(sorted, (booking) => booking.date?.seconds ?? 0);
    const bookingsByDay = days.map((day) => byDay[day.dateAsTimestamp.seconds]);
    return (day: Day | number | undefined) => {
      if (day === undefined) return [];
      const dayIndex = typeof day === "number" ? day : day.index;
      return bookingsByDay[dayIndex] ?? [];
    };
  }, [bookings, days]);
};

const useGetBookingCount = (bookings: Booking.AsObject[]) => {
  const { days } = useDates();

  return useMemo(() => {
    const bookingsByDay = groupBy(bookings, (booking) => booking.date?.seconds) as Dictionary<
      Booking.AsObject[] | undefined
    >;
    const bookingCountByDay = days.reduce(
      (map, day) => map.set(day, bookingsByDay[day.dateAsTimestamp.seconds]?.length ?? 0),
      new Map<Day, number>(),
    );
    return (day: Day | undefined) => (day ? bookingCountByDay.get(day) ?? 0 : 0);
  }, [bookings, days]);
};

interface BookingContextProps {
  initialBookings: Booking.AsObject[];
  maxBookingsPerDay: number;
}

const [bookingContext, useBookings] = createSuperContext(
  ({ initialBookings, maxBookingsPerDay }: BookingContextProps) => {
    const client = useApiClient();

    const [bookings, setBookings] = useState<Booking.AsObject[]>(initialBookings);

    const saveBookings = useCallback(
      async (bookings: Booking.AsObject[]) => {
        const request = messageFromObject(SaveBookingsRequest, {
          bookingsList: bookings.map((booking) => messageFromObject(Booking, booking)),
        });

        const response = await client.saveBookings(request);
        const newBookings = response.toObject().bookingsList;
        const updatedIds = new Set(bookings.map((booking) => booking.id));
        setBookings((state) => [
          ...state.filter((booking) => !updatedIds.has(booking.id)),
          ...newBookings,
        ]);
        return newBookings;
      },
      [setBookings, client],
    );

    const deleteBooking = useCallback(
      async (booking: Booking.AsObject | string) => {
        const id = typeof booking === "string" ? booking : booking.id;
        const request = new DeleteBookingsRequest();
        request.setIdsList([id]);
        await client.deleteBookings(request);
        setBookings((state) => state.filter((booking) => booking.id !== id));
      },
      [setBookings, client],
    );

    const getBooking = useGetBooking(bookings);
    const getBookingsByDay = useGetBookingsByDay(bookings);
    const getBookingCount = useGetBookingCount(bookings);

    return {
      /**
       * A list of Bookings in the current bookable range for every Desk on the currently selected Floor
       */
      bookings,
      /**
       * Save a list of bookings to the database. For each booking saved, if the id is empty a new
       * booking is created, otherwise a booking with the specified id is updated. bookings and
       * bookingsByDesk will be updated once the request succeeds.
       */
      saveBookings,
      /**
       * Delete a list of bookings. bookings and bookingsByDesk will be updated once the request succeeds.
       */
      deleteBooking,
      /**
       * Get a booking by desk (or desk id) and day (or day index).
       */
      getBooking,
      /**
       * Get a list of bookings by day.
       */
      getBookingsByDay,

      /**
       * Get the total number of bookings by day.
       */
      getBookingCount,
      /**
       * Max number of bookings per day
       */
      maxBookingsPerDay,
    };
  },
);

export { bookingContext, useBookings };
