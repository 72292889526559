import React from "react";
import { SuperContext } from "react-super-context";

import { Spinner } from "../Core/Components";
import {
  bookingContext,
  dateContext,
  deskContext,
  floorContext,
  memberBookingContext,
  memberContext,
  memberListContext,
} from "../Core/Contexts";
import useInitialData from "../Core/Hooks/InitialData";
import { ChildrenOnlyProps } from "../Core/Utilities";
import ErrorPage from "./ErrorPage";

const InitialDataLoader = ({ children }: ChildrenOnlyProps) => {
  const {
    error,
    isLoading,
    floors,
    desks,
    bookingRange,
    bookingCount,
    bookings,
    member,
  } = useInitialData();

  return error ? (
    <ErrorPage errorMessage={error.message} />
  ) : isLoading || !bookingRange || !member ? (
    <Spinner />
  ) : (
    <SuperContext
      contexts={[
        floorContext(floors),
        deskContext(desks),
        dateContext(bookingRange),
        bookingContext({
          initialBookings: bookings,
          maxBookingsPerDay: bookingCount?.maxBookingsPerDay ?? 0, // Shouldn't ever be undefined because of loaded check
        }),
        memberContext(member),
        memberListContext,
        memberBookingContext,
      ]}
    >
      {children}
    </SuperContext>
  );
};

export default InitialDataLoader;
