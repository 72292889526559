import React from "react";
import { Link } from "react-router-dom";

import { Button, IconButton, Svg } from "../Core/Components";
import { useFloors } from "../Core/Contexts";

const FloorSelector = () => {
  const { floors, setSelectedFloor } = useFloors();

  return (
    <div className="Floor__selector flex bg-projectGray flex-col justify-center h-screen">
      <div className="navbar-offset" />
      <div className="Floor__selector__content w-10/12 sm:w-8/12 flex-col justify-center py-4 sm:py-32 mx-auto">
        <div className="m-auto mb-6 w-16 sm:w-auto sm:mb-8 max-w-spark">
          <Svg name="Spark" />
        </div>

        <h1 className="text-center text-white text-2xl md:text-4xl font-bold">
          Reserver plass i Nøstegaten
        </h1>
        <p className="text-center text-white mt-3 md:text-xl">
          Du kan reservere plass en uke frem i tid.
        </p>
        <h2 className="mt-6 sm:mt-16 mb-8 text-center text-white md:text-2xl font-bold">
          Hvor vil du være?
        </h2>
        {floors.reverse().map((floor) => (
          <Button
            key={floor.id}
            color="pear"
            size="lg"
            onClick={() => setSelectedFloor(floor)}
            className="mb-6 mx-auto w-4/5 sm:w-3/5 lg:w-2/5 xl:w-2/6"
            animate={true}
          >
            {floor.description}
          </Button>
        ))}
        <IconButton icon={<Svg name="ArrowRight" />} className="block mx-auto">
          <Link
            to="free-booking"
            className="flex flex-row items-center font-bold py-2
                       border-b-2 border-solid border-projectVeryLightGray"
          >
            Reserver plass på kontoret uten arbeidspult
          </Link>
        </IconButton>
      </div>
      <div className="block flex-1 ml-auto pr-8 pb-8 overflow-hidden">
        <Svg name="Rabbit" />
      </div>
    </div>
  );
};

export default FloorSelector;
