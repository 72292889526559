import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function usePersistedState<TState = undefined>(
  key: string,
): [TState | undefined, Dispatch<SetStateAction<TState | undefined>>];
export function usePersistedState<TState>(
  key: string,
  initialState?: TState | (() => TState),
): [TState, Dispatch<SetStateAction<TState>>];
export function usePersistedState<TState>(
  key: string,
  initialState?: TState | (() => TState),
): [TState | undefined, Dispatch<SetStateAction<TState | undefined>>] {
  const [state, setState] = useState(() => {
    const item = localStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item) as TState;
      } catch (error: unknown) {
        console.error(`Failed to parse persisted state to json. key=${key}`, item, error);
        localStorage.removeItem(key);
        throw error;
      }
    }
    return initialState instanceof Function ? initialState() : initialState;
  });

  useEffect(() => {
    if (state !== undefined && state !== null) localStorage.setItem(key, JSON.stringify(state));
    else localStorage.removeItem(key);
  }, [key, state]);

  return [state, setState];
}
