import React, { Dispatch } from "react";

import { Modal, ModalBody, ModalHeader } from "../Core/Components";
import MemberSelectForm from "./MemberSelectForm";

interface MemberSelectModalProps {
  dialogOpen: boolean;
  setDialogOpen: Dispatch<boolean>;
}

const MemberSelectModal = ({ dialogOpen, setDialogOpen }: MemberSelectModalProps) => {
  return (
    <Modal open={dialogOpen} onClose={() => setDialogOpen(false)}>
      <ModalHeader>Hvem vil du reservere plass for?</ModalHeader>
      <ModalBody>
        <p>
          Du kan reservere både for Knowit ansatte og for eksterne personer som kunder eller andre
          som trenger en arbeidspult i N58.
        </p>

        <div className="h-112">
          {dialogOpen && <MemberSelectForm setDialogOpen={setDialogOpen} />}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MemberSelectModal;
