// source: services.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var floors_pb = require('./floors_pb.js');
goog.object.extend(proto, floors_pb);
var desks_pb = require('./desks_pb.js');
goog.object.extend(proto, desks_pb);
var bookings_pb = require('./bookings_pb.js');
goog.object.extend(proto, bookings_pb);
var parking_areas_pb = require('./parking_areas_pb.js');
goog.object.extend(proto, parking_areas_pb);
var parking_bookings_pb = require('./parking_bookings_pb.js');
goog.object.extend(proto, parking_bookings_pb);
var members_pb = require('./members_pb.js');
goog.object.extend(proto, members_pb);
var google_api_annotations_pb = require('./google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
