import classnames from "classnames";
import React from "react";

import { Svg } from "../Core/Components";
import { DeskState } from "../Core/Hooks/DeskState";
import { NativeButtonProps } from "../Core/Utilities";

interface DeskAvailabilityEntryProps {
  selected: boolean;
  state: DeskState;
  displayText: string;
  displayDate: string;
}

const Checkbox = ({ checked }: { checked: boolean }) => (
  <div className="w-6 h-6 rounded border-solid border-2 border-green-400 bg-white">
    {checked && <Svg name="CheckboxIcon" full />}
  </div>
);

const BookingFormDayButton = ({
  selected,
  state,
  displayText,
  displayDate,
  disabled,
  ...rest
}: DeskAvailabilityEntryProps & NativeButtonProps) => {
  return (
    <button
      disabled={disabled}
      {...rest}
      className={classnames(
        "flex flex-row flex-initial text-projectGray bg-pear w-full mb-3 last:mb-0 items-center h-16 p-3 rounded shadow-md",
        {
          "bg-flamingo pointer-events-none": disabled,
          "bg-statusGreen": selected,
          "hover:bg-statusGreen": !disabled,
        },
      )}
    >
      <div className="block flex-1">
        <h4 className={"text-sm leading-4 mb-1 font-bold text-left"}>{displayDate}</h4>
        <p className={"text-base leading-4 font-normal text-left whitespace-pre-wrap"}>
          {displayText}
        </p>
      </div>
      {state === "available" && (
        <div className="ml-5 mr-2">
          <Checkbox checked={selected} />
        </div>
      )}
      {state === "swappable" &&
        (selected ? (
          <div className="ml-5 mr-2">
            <Checkbox checked={true} />
          </div>
        ) : (
          <div className="w-8 h-8 ml-5 mr-2">
            <Svg name="SwapDesk" full />
          </div>
        ))}
    </button>
  );
};

export default BookingFormDayButton;
