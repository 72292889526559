import React from "react";

import { BookingForm } from "../BookingForm";
import { SubPageLayout } from "../Core/Components";

const FreeBooking = () => {
  return (
    <SubPageLayout title="Skal på kontoret, men trenger ikke arbeidspult">
      <p className="text-center text-white my-3 md:text-xl">
        Har du behov for å være i Nøstegaten, men trenger ikke arbeidspult, kan du reservere plass
        her.
      </p>
      <div className="flex bg-projectGray flex-col">
        <div className="w-11/12 sm:w-8/12 justify-center py-2 mx-auto">
          <div className="flex justify-center">
            <BookingForm freeBooking />
          </div>
        </div>
      </div>
    </SubPageLayout>
  );
};

export default FreeBooking;
