import React from "react";

import { IconButton, Svg, SvgName } from "../Core/Components";
import { Desk, DeskType } from "../Generated/desks_pb";
import DeskDetailsModal from "./DeskDetailsModal";

const DeskIcons = Object.freeze({
  [DeskType.DESK_TYPE_UNDEFINED]: "UniversalIcon",
  [DeskType.DESK_TYPE_DEVELOPER]: "DeveloperIcon",
  [DeskType.DESK_TYPE_DESIGNER]: "DesignerIcon",
} as Record<DeskType, SvgName>);

const DeskIconsGreen = Object.freeze({
  [DeskType.DESK_TYPE_UNDEFINED]: "UniversalIconGreen",
  [DeskType.DESK_TYPE_DEVELOPER]: "DeveloperIconGreen",
  [DeskType.DESK_TYPE_DESIGNER]: "DesignerIconGreen",
} as Record<DeskType, SvgName>);

const HeaderTexts = Object.freeze({
  [DeskType.DESK_TYPE_UNDEFINED]: "Passer for alle",
  [DeskType.DESK_TYPE_DEVELOPER]: "Passer for utviklere",
  [DeskType.DESK_TYPE_DESIGNER]: "Passer for designere",
} as Record<DeskType, string>);

interface FurtherDetailsProps {
  desk?: Desk.AsObject;
}

const DeskDetails = ({ desk }: FurtherDetailsProps) => {
  const [showModal, setShowModal] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Something funky going on with the generated types here
  const deskType: DeskType = desk?.type ?? DeskType.DESK_TYPE_UNDEFINED;

  const displayHeader = HeaderTexts[deskType];
  const displayIcon = showModal ? DeskIconsGreen[deskType] : DeskIcons[deskType];
  const displayText = desk?.equipment ?? "Ingen beskrivelse lagt inn.";

  return (
    <div className="relative -ml-2">
      <IconButton
        icon={<Svg name={displayIcon} full className="w-8 h-8" />}
        onClick={() => setShowModal(!showModal)}
      />

      {showModal && (
        <DeskDetailsModal
          details={displayText}
          header={displayHeader}
          onClick={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default DeskDetails;
