import classnames from "classnames";
import React, { PropsWithChildren, useMemo } from "react";

import { NativeButtonProps } from "../Utilities";

type ButtonColor =
  | "pear"
  | "flamingo"
  | "statusGreen"
  | "statusRed"
  | "gray"
  | "black"
  | "transparentOutlined";
type ButtonSize = "md" | "lg" | "fit";

interface ButtonProps {
  color?: ButtonColor;
  animate?: boolean;
  size?: ButtonSize;
}

interface ButtonStyle {
  buttonColor: string;
  hoverColor: string;
  focusColor: string;
  activeColor: string;
  textColor?: string;
  borderColor?: string;
  borderStyle?: string;
  border?: string;
}

type ButtonStyleDictionary = {
  [key in ButtonColor]: ButtonStyle;
};

const buttonStyles: ButtonStyleDictionary = {
  pear: {
    buttonColor: "bg-pear",
    hoverColor: "hover:bg-statusGreen",
    focusColor: "focus:bg-statusGreen",
    activeColor: "active:bg-statusGreen",
  },
  flamingo: {
    buttonColor: "bg-flamingo",
    hoverColor: "hover:bg-statusRed",
    focusColor: "focus:bg-statusRed",
    activeColor: "active:bg-statusRed",
  },
  statusGreen: {
    buttonColor: "bg-statusGreen",
    hoverColor: "hover:bg-statusGreen",
    focusColor: "focus:bg-statusGreen",
    activeColor: "active:bg-statusGreen",
  },
  statusRed: {
    buttonColor: "bg-statusRed",
    hoverColor: "hover:bg-statusRed",
    focusColor: "focus:bg-statusRed",
    activeColor: "active:bg-statusRed",
  },
  gray: {
    buttonColor: "bg-projectGray",
    hoverColor: "hover:bg-projectLightGray",
    focusColor: "focus:bg-projectLightGray",
    activeColor: "active:bg-projectLightGray",
    textColor: "text-white",
  },
  black: {
    buttonColor: "bg-black",
    hoverColor: "",
    focusColor: "",
    activeColor: "",
    textColor: "text-white",
  },
  transparentOutlined: {
    buttonColor: "bg-transparent",
    borderColor: "border-white",
    borderStyle: "border-solid",
    border: "border",
    textColor: "text-white",
    hoverColor: "hover:bg-projectLightGray",
    focusColor: "focus:bg-projectLightGray",
    activeColor: "active:bg-projectLightGray",
  },
};

const Button = ({
  color = "pear",
  size = "md",
  animate = false,
  className,
  disabled,
  children,
  ...rest
}: PropsWithChildren<ButtonProps & NativeButtonProps>) => {
  const {
    buttonColor,
    hoverColor,
    focusColor,
    activeColor,
    textColor = "text-black",
    border = "",
    borderColor = "",
    borderStyle = "",
  } = buttonStyles[color];

  const btnSize = useMemo(() => {
    switch (size) {
      case "fit":
        return "p-1 rounded";
      case "md":
        return `h-12 p-2 px-8 rounded font-bold`;
      default:
        return `py-6 p-10 rounded-lg sm:px-20 sm:text-2xl font-bold`;
    }
  }, [size]);

  return (
    <button
      {...rest}
      className={classnames(
        "shadow-xl block whitespace-nowrap items-center5",
        disabled ? "bg-gray-600" : buttonColor,
        !disabled && hoverColor,
        !disabled && focusColor,
        !disabled && activeColor,
        border,
        borderColor,
        borderStyle,
        textColor,
        btnSize,
        className,
        {
          "transform transition-transform duration-100 hover:scale-10": animate,
        },
      )}
    >
      {children}
    </button>
  );
};

export default Button;
