import classnames from "classnames";
import React, { useMemo } from "react";

import { calculatePositionStyles, DeskPosition } from ".";
import { useBookings, useDates, useDesks } from "../../Core/Contexts";
import { useOfficeFull } from "../../Core/Hooks";
import useDeskState, { DeskState } from "../../Core/Hooks/DeskState";
import { Desk } from "../../Generated/desks_pb";

const getStyleByState = (deskState: DeskState, selected: boolean) => {
  switch (deskState) {
    case "disabled":
      return "disabled-desk";
    case "taken":
    case "full":
      return selected ? "bg-statusRed" : "bg-flamingo hover:bg-statusRed active:bg-statusRed";
    case "swappable":
      return selected ? "bg-statusGreen" : "bg-sand hover:bg-statusGreen active:bg-statusGreen";
    case "available":
      return selected ? "bg-statusGreen" : "bg-pear hover:bg-statusGreen active:bg-statusGreen";
    default:
      return "";
  }
};

const DeskButton = ({
  desk,
  number,
  defaultDeskWidth,
  deskPosition,
}: {
  deskPosition: DeskPosition;
  number: string;
  defaultDeskWidth: number;
  desk?: Desk.AsObject;
}) => {
  const { getBooking } = useBookings();
  const { selectedDay } = useDates();
  const { selectedDesk, setSelectedDesk } = useDesks();
  const { isOfficeFull } = useOfficeFull();

  const getDeskState = useDeskState(desk, false);
  const deskState = useMemo<DeskState>(() => getDeskState(selectedDay), [
    getDeskState,
    selectedDay,
  ]);

  const buttonText = useMemo(() => {
    if (deskState === "taken") {
      const name = getBooking(desk, selectedDay)?.member?.name;
      if (!name) return "Unknown";

      const [first, ...last] = name.split(/\s+/);
      return last.length ? `${first} ${last[last.length - 1].charAt(0)}.` : first;
    }
    if (deskState === "full") return "Fullt";
    return deskState === "swappable" && isOfficeFull ? "Tilgjengelig" : "Ledig";
  }, [getBooking, deskState, isOfficeFull, desk, selectedDay]);

  return (
    <button
      className={classnames(
        "Desk-Button",
        getStyleByState(
          deskState === "swappable" && !isOfficeFull ? "available" : deskState,
          selectedDesk === desk,
        ),
        deskState === "disabled" ? "text-white" : "text-black",
        "p-1",
        "rounded",
        "flex",
        "items-center",
        "justify-center",
        "relative",
        "shadow-md",
        "leading-none",
      )}
      style={{
        ...calculatePositionStyles(deskPosition, defaultDeskWidth, 2),
      }}
      disabled={deskState === "disabled"}
      onClick={() => {
        if (desk) setSelectedDesk(desk);
      }}
    >
      {deskState !== "disabled" && <span className="text-sm font-bold">{buttonText}</span>}
      <div className="absolute text-xxs top-1 right-1">{number}</div>
    </button>
  );
};

export default DeskButton;
