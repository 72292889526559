import { createSuperContext } from "react-super-context";

import { Floor } from "../../Generated/floors_pb";
import { usePersistedState } from "../Hooks/LocalStorageState";

const [floorContext, useFloors] = createSuperContext((floors: Floor.AsObject[]) => {
  const [selectedFloor, setSelectedFloor] = usePersistedState<Floor.AsObject | undefined>(
    "selectedFloor",
  );

  return {
    /**
     * List of selectable Floors
     */
    floors,
    /**
     * The currently selected Floor (in {@link floors}).
     */
    selectedFloor,
    /**
     * Set the selected floor.
     */
    setSelectedFloor,
  };
});

export { floorContext, useFloors };
