export const currentDay = () => {
  const day = new Date();
  day.setHours(0, 0, 0, 0);
  return day;
};

export const datePlusDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const formatDate = (date?: Date, options: Intl.DateTimeFormatOptions = {}) => {
  if (!date) return "";
  const actualOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "2-digit",
    month: "2-digit",
    ...options,
  };
  const string = date.toLocaleDateString("no", actualOptions);

  if (actualOptions.weekday === "long" || actualOptions.weekday === "short") {
    return string[0].toUpperCase() + string.substring(1);
  }
  return string;
};
