import keyBy from "lodash/keyBy";
import React, { useEffect, useMemo, useState } from "react";

import { Button, Modal, ModalBody, ModalButtons, ModalHeader, Spinner } from "../Core/Components";
import { useDates, useDesks } from "../Core/Contexts";
import { Day, formatDate, GrpcError } from "../Core/Utilities";
import { Booking } from "../Generated/bookings_pb";

interface DeleteBookingModalProps {
  booking: Booking.AsObject | undefined;
  close: () => void;
  thirdPerson: boolean;
  deleteFunction: (booking: Booking.AsObject) => Promise<void>;
}

const DeleteBookingModal = ({
  booking,
  close,
  thirdPerson,
  deleteFunction,
}: DeleteBookingModalProps) => {
  const { days } = useDates();
  const { getDeskById } = useDesks();

  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState<GrpcError>();

  const handleDelete = async () => {
    if (!booking) return;
    setDeleteError(undefined);
    try {
      setDeleteLoading(true);
      await deleteFunction(booking);
      setDeleteLoading(false);
      close();
    } catch (err: unknown) {
      console.error("Failed to delete booking", err);
      setDeleteError(err as GrpcError);
      setDeleteLoading(false);
    }
  };

  // Keeps the booking in state while the close animation plays
  const [bookingDelayed, setBookingDelayed] = useState(booking);

  useEffect(() => {
    if (booking !== undefined) {
      setBookingDelayed(booking);
    }
  }, [booking]);

  const daysBySeconds = useMemo(() => keyBy(days, (day) => day.dateAsTimestamp.seconds), [days]);
  const day = daysBySeconds[bookingDelayed?.date?.seconds ?? 0] as Day | undefined;

  return (
    <Modal open={!!booking} onClose={close}>
      <ModalHeader>
        Er du sikker på at du vil kansellere reservasjonen
        {thirdPerson ? ` til ${bookingDelayed?.member?.name}` : " din"}?
      </ModalHeader>
      <ModalBody>
        {bookingDelayed?.deskId.length !== 0 && (
          <>
            <span>Plass {getDeskById(bookingDelayed?.deskId)?.number ?? "Ukjent"}</span>,&nbsp;
          </>
        )}
        {day && <span>{formatDate(day.date)}</span>}
        {isDeleteLoading && <Spinner />}
        {deleteError && <span className="text-statusRed">{deleteError.message}</span>}
      </ModalBody>
      <ModalButtons>
        <Button color="transparentOutlined" className="min-w-16 mx-2" onClick={close}>
          Avbryt
        </Button>
        <Button color="statusRed" className="min-w-16 mx-2" onClick={handleDelete}>
          Kanseller
        </Button>
      </ModalButtons>
    </Modal>
  );
};

export default DeleteBookingModal;
