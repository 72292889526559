import classnames from "classnames";
import React, { InputHTMLAttributes, PropsWithChildren } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelBackground?: "black" | "white";
  name: string;
  inputClassName?: string;
}

const Input = ({
  id,
  label,
  labelBackground = "black",
  name,
  className,
  inputClassName,
  children,
  disabled,
  ...rest
}: PropsWithChildren<InputProps>) => {
  const actualId = id ?? `input_${name}`;
  return (
    <fieldset className={classnames("custom-fieldset", className)}>
      {label && (
        <label
          htmlFor={actualId}
          className={classnames({
            "bg-black": labelBackground === "black",
            "bg-white": labelBackground === "white",
            "text-gray-400": disabled,
          })}
        >
          {label}
        </label>
      )}
      <input
        {...rest}
        id={actualId}
        disabled={disabled}
        className={classnames(
          "border-solid border border-white p-3 rounded bg-transparent w-full",
          { "text-gray-600 border-gray-400": disabled },
          inputClassName,
        )}
      />
      {children}
    </fieldset>
  );
};

export default Input;
