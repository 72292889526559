/* eslint-disable max-lines -- Special case file, don't care if it's long */
export interface Position {
  x: number;
  y: number;
  rotation?: 0 | 90 | 180 | 270;
  width?: number;
  height?: number;
}

export interface FloorElement extends Position {
  type:
    | "Meeting"
    | "Silent"
    | "Kitchen"
    | "Toilet"
    | "Lift"
    | "Gaming"
    | "Blank"
    | "Stairs"
    | "Accounting"
    | "Printer";
  title?: string;
}

interface FloorLayout {
  columns: number;
  rows: number;
  defaultDeskWidth: number;
  desks: Record<string, DeskPosition>;
  elements: FloorElement[];
}

export type DeskPosition = Position;

const floorDefaults = {
  columns: 50,
  rows: 50,
  defaultDeskWidth: 4,
};

const defaultElements: FloorElement[] = [
  {
    type: "Blank",
    title: "",
    x: 17,
    y: 11,
    width: 12,
    height: 16,
  },
  {
    type: "Lift",
    title: "",
    x: 20,
    y: 24,
    width: 6,
    height: 2,
  },
  {
    type: "Kitchen",
    title: "",
    x: 34,
    y: 10,
    width: 7,
    height: 7,
  },
  {
    type: "Toilet",
    title: "",
    x: 34,
    y: 19,
    width: 7,
    height: 7,
  },
  {
    type: "Stairs",
    title: "",
    x: 21,
    y: 32,
    width: 8,
    height: 3,
  },
];

const seventhFloorElements: FloorElement[] = [
  {
    type: "Meeting",
    title: "Holy Grail, Ramones, Red Rum",
    x: 17,
    y: 8,
    width: 12,
    height: 3,
  },
  {
    type: "Meeting",
    title: "Post it",
    x: 1,
    y: 27,
    width: 10,
    height: 2,
  },
  {
    type: "Silent",
    title: "",
    x: 17,
    y: 29,
    width: 3,
    height: 4,
  },
  {
    type: "Meeting",
    title: "Maslow",
    x: 21,
    y: 29,
    width: 8,
    height: 3,
  },
  {
    type: "Meeting",
    title: "Ziggy, Atari",
    x: 17,
    y: 33,
    width: 3,
    height: 5,
  },
  {
    type: "Silent",
    title: "",
    x: 21,
    y: 35,
    width: 4,
    height: 3,
  },
  {
    type: "Meeting",
    title: "Ping Pong",
    x: 25,
    y: 35,
    width: 4,
    height: 3,
  },
];

const sixthFloorElements: FloorElement[] = [
  {
    type: "Accounting",
    title: "Regnskap",
    x: 1,
    y: 1,
    width: 12,
    height: 3,
  },
  {
    type: "Meeting",
    title: "Yoko, Gropius",
    x: 17,
    y: 8,
    width: 12,
    height: 3,
  },
  {
    type: "Meeting",
    title: "Brown Fox, Folketrygden",
    x: 1,
    y: 25,
    width: 12,
    height: 4,
  },
  {
    type: "Meeting",
    title: "Mento-menter",
    x: 17,
    y: 29,
    width: 3,
    height: 6,
  },
  {
    type: "Printer",
    title: "",
    x: 21,
    y: 29,
    width: 8,
    height: 3,
  },
  {
    type: "Meeting",
    title: "Dødtid, Respatex",
    x: 17,
    y: 37,
    width: 3,
    height: 9,
  },
  {
    type: "Meeting",
    title: "Basic",
    x: 20,
    y: 40,
    width: 6,
    height: 6,
  },
  {
    type: "Meeting",
    title: "Kennedy",
    x: 26,
    y: 40,
    width: 6,
    height: 6,
  },
  {
    type: "Meeting",
    title: "Spacewar",
    x: 32,
    y: 40,
    width: 9,
    height: 6,
  },
  {
    type: "Meeting",
    title: "Snikreklame",
    x: 32,
    y: 34,
    width: 9,
    height: 6,
  },
  {
    type: "Meeting",
    title: "Prytz",
    x: 32,
    y: 28,
    width: 9,
    height: 6,
  },
  {
    type: "Kitchen",
    title: "",
    x: 34,
    y: 26,
    width: 7,
    height: 2,
  },
  {
    type: "Gaming",
    title: "",
    x: 34,
    y: 5,
    width: 7,
    height: 5,
  },
];

const fitfhFloorElements: FloorElement[] = [
  {
    type: "Meeting",
    title: "NeueHaas",
    x: 17,
    y: 1,
    width: 4,
    height: 3,
  },
  {
    type: "Silent",
    title: "",
    x: 17,
    y: 4,
    width: 4,
    height: 3,
  },
  {
    type: "Blank",
    title: "KCB",
    x: 21,
    y: 1,
    width: 8,
    height: 6,
  },
  {
    type: "Meeting",
    title: "Simone, Tim, Agnar",
    x: 17,
    y: 8,
    width: 12,
    height: 3,
  },
  {
    type: "Meeting",
    title: "Forbrukerrådet",
    x: 1,
    y: 31,
    width: 10,
    height: 2,
  },
  {
    type: "Silent",
    title: "",
    x: 17,
    y: 29,
    width: 3,
    height: 4,
  },
  {
    type: "Blank",
    title: "",
    x: 17,
    y: 33,
    width: 3,
    height: 5,
  },
  {
    type: "Meeting",
    title: "Diners club",
    x: 21,
    y: 29,
    width: 8,
    height: 3,
  },
  {
    type: "Meeting",
    title: "High noon",
    x: 21,
    y: 35,
    width: 4,
    height: 3,
  },
  {
    type: "Silent",
    title: "",
    x: 25,
    y: 35,
    width: 4,
    height: 3,
  },
  {
    type: "Meeting",
    title: "Ludvig",
    x: 29,
    y: 32,
    width: 4,
    height: 3,
  },
  {
    type: "Blank",
    title: "",
    x: 29,
    y: 35,
    width: 4,
    height: 3,
  },
];

export const floorLayout: Record<string, FloorLayout> = {
  "195e60f2-fbdd-43f8-8e2f-86d7e731099b": {
    // Floor 5
    ...floorDefaults,
    ...fitfhFloorElements,
    desks: {
      "501": { x: 1, y: 1 },
      "502": { x: 5, y: 1 },
      "503": { x: 1, y: 3 },
      "504": { x: 5, y: 3 },

      "505": { x: 1, y: 6 },
      "506": { x: 5, y: 6 },
      "507": { x: 1, y: 8 },
      "508": { x: 5, y: 8 },

      "509": { x: 1, y: 11 },
      "510": { x: 5, y: 11 },
      "511": { x: 1, y: 13 },
      "512": { x: 5, y: 13 },

      "513": { x: 1, y: 16 },
      "514": { x: 5, y: 16 },
      "515": { x: 1, y: 18 },
      "516": { x: 5, y: 18 },

      // Solutions estetikk. Trenge ikkje nummer på kartet.
      "517": { x: 1, y: 21 },
      "518": { x: 5, y: 21 },
      "519": { x: 1, y: 23 },
      "520": { x: 5, y: 23 },

      "521": { x: 1, y: 26 },
      "522": { x: 5, y: 26 },
      "523": { x: 1, y: 28 },
      "524": { x: 5, y: 28 },

      "525": { x: 1, y: 34 },
      "526": { x: 5, y: 34 },
      "527": { x: 1, y: 36 },
      "528": { x: 5, y: 36 },

      "529": { x: 1, y: 39 },
      "530": { x: 5, y: 39 },
      "531": { x: 1, y: 41 },
      "532": { x: 5, y: 41 },

      "533": { x: 1, y: 44 },
      "534": { x: 5, y: 44 },
      "535": { x: 1, y: 46 },
      "536": { x: 5, y: 46 },

      "537": { x: 13, y: 46, rotation: 270 },
      "538": { x: 15, y: 42, rotation: 270 },
      "539": { x: 13, y: 42, rotation: 270 },
      "540": { x: 15, y: 46, rotation: 270 },

      "541": { x: 19, y: 46, rotation: 270 },
      "542": { x: 21, y: 42, rotation: 270 },
      "543": { x: 19, y: 42, rotation: 270 },
      "544": { x: 21, y: 46, rotation: 270 },

      "545": { x: 25, y: 46, rotation: 270 },
      "546": { x: 27, y: 42, rotation: 270 },
      "547": { x: 25, y: 42, rotation: 270 },
      "548": { x: 27, y: 46, rotation: 270 },

      "549": { x: 31, y: 46, rotation: 270 },
      "550": { x: 33, y: 42, rotation: 270 },
      "551": { x: 31, y: 42, rotation: 270 },
      "552": { x: 33, y: 46, rotation: 270 },

      "553": { x: 37, y: 46, rotation: 270 },
      "554": { x: 37, y: 42, rotation: 270 },
      "555": { x: 39, y: 42, rotation: 270 },
      "556": { x: 39, y: 46, rotation: 270 },

      "557": { x: 43, y: 43 },
      "558": { x: 47, y: 43 },
      "559": { x: 43, y: 45 },
      "560": { x: 47, y: 45 },

      "561": { x: 43, y: 38 },
      "562": { x: 47, y: 38 },
      "563": { x: 43, y: 40 },
      "564": { x: 47, y: 40 },

      "565": { x: 43, y: 35 },
      "566": { x: 47, y: 35 },
      "567": { x: 43, y: 33 },
      "568": { x: 47, y: 33 },

      "569": { x: 43, y: 30 },
      "570": { x: 47, y: 30 },
      "571": { x: 43, y: 28 },
      "572": { x: 47, y: 28 },
    },
    elements: [...defaultElements, ...fitfhFloorElements],
  },
  "acb23a74-0005-4e97-9346-85ca0d5a5815": {
    // Floor 6
    ...floorDefaults,
    ...sixthFloorElements,
    desks: {
      "601": { x: 1, y: 5 },
      "602": { x: 5, y: 5 },
      "603": { x: 1, y: 7 },
      "604": { x: 5, y: 7 },

      "605": { x: 1, y: 10 },
      "606": { x: 5, y: 10 },
      "607": { x: 1, y: 12 },
      "608": { x: 5, y: 12 },

      "609": { x: 1, y: 15 },
      "610": { x: 5, y: 15 },
      "611": { x: 9, y: 15 },
      "612": { x: 1, y: 17 },
      "613": { x: 5, y: 17 },
      "614": { x: 9, y: 17 },

      "615": { x: 1, y: 20 },
      "616": { x: 5, y: 20 },
      "617": { x: 9, y: 20 },
      "618": { x: 1, y: 22 },
      "619": { x: 5, y: 22 },
      "620": { x: 9, y: 22 },

      // Adult room
      "621": { x: 1, y: 30 },
      "622": { x: 5, y: 30 },
      "623": { x: 9, y: 30 },
      "624": { x: 1, y: 32 },
      "625": { x: 5, y: 32 },
      "626": { x: 9, y: 32 },

      "627": { x: 1, y: 36 },
      "628": { x: 5, y: 36 },
      "629": { x: 9, y: 36 },
      "630": { x: 1, y: 38 },
      "631": { x: 5, y: 38 },
      "632": { x: 9, y: 38 },

      "633": { x: 1, y: 42 },
      "634": { x: 5, y: 42 },
      "635": { x: 9, y: 42 },
      "636": { x: 1, y: 44 },
      "637": { x: 5, y: 44 },
      "638": { x: 9, y: 44 },
    },
    elements: [...defaultElements, ...sixthFloorElements],
  },
  "c84b50b1-054e-49bc-860c-4af77b119791": {
    // Floor 7
    ...floorDefaults,
    ...seventhFloorElements,
    desks: {
      "701": { x: 1, y: 1 },
      "702": { x: 5, y: 1 },
      "703": { x: 1, y: 3 },
      "704": { x: 5, y: 3 },

      "705": { x: 1, y: 6 },
      "706": { x: 5, y: 6 },
      "707": { x: 1, y: 8 },
      "708": { x: 5, y: 8 },

      "709": { x: 1, y: 11 },
      "710": { x: 5, y: 11 },
      "711": { x: 9, y: 11 },
      "712": { x: 1, y: 13 },
      "713": { x: 5, y: 13 },
      "714": { x: 9, y: 13 },

      "715": { x: 1, y: 17 },
      "716": { x: 5, y: 17 },
      "717": { x: 9, y: 17 },
      "718": { x: 1, y: 19 },
      "719": { x: 5, y: 19 },
      "720": { x: 9, y: 19 },

      "721": { x: 1, y: 22 },
      "722": { x: 5, y: 22 },
      "723": { x: 9, y: 22 },
      "724": { x: 1, y: 24 },
      "725": { x: 5, y: 24 },
      "726": { x: 9, y: 24 },

      "727": { x: 1, y: 30 },
      "728": { x: 5, y: 30 },
      "729": { x: 9, y: 30 },
      "730": { x: 1, y: 32 },
      "731": { x: 5, y: 32 },
      "732": { x: 9, y: 32 },

      "733": { x: 1, y: 35 },
      "734": { x: 5, y: 35 },
      "735": { x: 9, y: 35 },
      "736": { x: 1, y: 37 },
      "737": { x: 5, y: 37 },
      "738": { x: 9, y: 37 },

      "739": { x: 5, y: 44, rotation: 270 },
      "740": { x: 5, y: 40, rotation: 270 },
      "741": { x: 7, y: 44, rotation: 270 },
      "742": { x: 7, y: 40, rotation: 270 },

      "743": { x: 13, y: 44, rotation: 270 },
      "744": { x: 13, y: 40, rotation: 270 },
      "745": { x: 15, y: 44, rotation: 270 },
      "746": { x: 15, y: 40, rotation: 270 },

      "747": { x: 21, y: 44, rotation: 270 },
      "748": { x: 21, y: 40, rotation: 270 },
      "749": { x: 23, y: 44, rotation: 270 },
      "750": { x: 23, y: 40, rotation: 270 },

      "751": { x: 29, y: 44, rotation: 270 },
      "752": { x: 29, y: 40, rotation: 270 },
      "753": { x: 31, y: 44, rotation: 270 },
      "754": { x: 31, y: 40, rotation: 270 },

      "755": { x: 37, y: 44, rotation: 270 },
      "756": { x: 37, y: 40, rotation: 270 },
      "757": { x: 39, y: 44, rotation: 270 },
      "758": { x: 39, y: 40, rotation: 270 },

      "762": { x: 33, y: 35 },
      "761": { x: 37, y: 35 },
      "760": { x: 33, y: 37 },
      "759": { x: 37, y: 37 },

      "764": { x: 33, y: 32 },
      "763": { x: 37, y: 32 },
      "766": { x: 33, y: 30 },
      "765": { x: 37, y: 30 },

      "767": { x: 41, y: 1, rotation: 270 },
      "768": { x: 39, y: 1, rotation: 270 },

      "769": { x: 34, y: 1, rotation: 270 },
      "770": { x: 32, y: 1, rotation: 270 },

      "771": { x: 27, y: 1, rotation: 270 },
      "772": { x: 25, y: 1, rotation: 270 },

      "773": { x: 20, y: 1, rotation: 270 },
      "774": { x: 18, y: 1, rotation: 270 },
    },

    elements: [...defaultElements, ...seventhFloorElements],
  },
};

export const calculatePositionStyles = (
  { x, y, width, height, rotation }: Position,
  defaultWidth = 5,
  defaultHeight = 5,
) => {
  return {
    gridRow: `${y}/${y + (height ?? defaultHeight)}`,
    gridColumn: `${x}/${x + (width ?? defaultWidth)}`,
    transform: `rotate(${rotation}deg) translateX(-50%)`,
    transformOrigin: "bottom left",
  };
};
