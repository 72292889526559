import { useCallback } from "react";

import { Desk } from "../../Generated/desks_pb";
import { useBookings, useMemberBookings } from "../Contexts";
import { Day } from "../Utilities";
import { useOfficeFull } from "./OfficeFull";

export type DeskState =
  /**
   * Desks have not yet loaded, the desk does not exist in the db or the desk is disabled.
   */
  | "disabled"
  /**
   * The office is full, but the selected member has booked another desk and may swap to this desk.
   */
  | "swappable"
  /**
   * The desk is not booked, but office is full and the selected member cannot book this desk.
   */
  | "full"
  /**
   * The desk has a booking.
   */
  | "taken"
  /**
   * The desk has no booking and may be booked.
   */
  | "available";

const useDeskState = (desk: Desk.AsObject | undefined, freeBooking: boolean) => {
  const { getBooking } = useBookings();
  const { checkFull } = useOfficeFull();
  const { getMemberBooking } = useMemberBookings();

  return useCallback(
    (day: Day | undefined): DeskState => {
      if (desk?.disabled ?? !freeBooking) return "disabled";

      if (getBooking(desk, day)) return "taken";

      const memberBookingOnDay = day && getMemberBooking(day);
      if (memberBookingOnDay && memberBookingOnDay.deskId !== desk?.id) return "swappable";

      return checkFull(day) ? "full" : "available";
    },
    [desk, checkFull, getBooking, getMemberBooking, freeBooking],
  );
};

export default useDeskState;
