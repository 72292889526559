import { Configuration } from "@azure/msal-browser";

const clientId = "9fd792c0-279b-4608-a02b-a5f0b737227a";
const tenantId = "6735929c-9dbf-473b-9fc6-5fbdcd2c9fc4";

const MsalConfiguration: Configuration = Object.freeze({
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- process.env not typed well enough (or could actually never be undefined, but check just to be sure)
    redirectUri: window.origin + (process.env.PUBLIC_URL ?? ""),
  },
});

const MsalScopes = Object.freeze([
  "https://knowitgroup.com/3b285574-c8ac-4c1c-951f-813dbef64a7f/Bookit.Default",
]) as string[];

export { MsalConfiguration, MsalScopes };
