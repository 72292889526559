import { Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";

import { StatusMessage } from ".";
import { BookingForm } from "../BookingForm";
import { useDesks } from "../Core/Contexts";
import { useOfficeFull } from "../Core/Hooks";
import { Toolbar } from "../Toolbar";
import { Floor, MapBoundaries } from "./Floor";

const BookingMap = () => {
  const { selectedDesk, setSelectedDesk } = useDesks();
  const { isOfficeFull } = useOfficeFull();

  const [showFullStatus, setShowFullStatus] = useState(isOfficeFull);

  useEffect(() => () => setSelectedDesk(undefined), [setSelectedDesk]);

  return (
    <div className="Book__desk bg-projectGray h-screen w-screen text-white flex flex-col">
      <div className="navbar-offset bg-black" />
      <Toolbar />
      {showFullStatus && (
        <div className="bg-black z-11">
          <StatusMessage
            title="Det er fullt på kontoret"
            message="Hvis du allerede har reservert, kan du bytte til en tilgjengelig plass"
            onClose={() => setShowFullStatus(false)}
          />
        </div>
      )}
      <div className="Map flex flex-col flex-1 overflow-hidden">
        <MapBoundaries type="DashedLines" description="Mot gaten" position="Top" />
        <div className="flex-1 overflow-auto">{<Floor />}</div>
        <MapBoundaries type="Waves" description="Mot sjøen" position="Bottom" />
      </div>
      <div className="fixed top-0 bottom-0 right-0 z-20">
        <Transition
          className="relative h-full w-112 max-w-screen bg-black shadow-3xl rounded"
          show={!!selectedDesk}
          enter="transition-all duration-200"
          enterFrom="transform translate-x-112 opacity-0"
          enterTo="transform translate-x-0 opacity-100"
          leave="transition-all duration-200"
          leaveFrom="transform translate-x-0 opacity-100"
          leaveTo="transform translate-x-112 opacity-0"
        >
          <BookingForm />
        </Transition>
      </div>
    </div>
  );
};

export default BookingMap;
