import sortBy from "lodash/sortBy";
import usePromise from "react-use-promise";

import {
  GetBookingCountRequest,
  GetBookingRangeRequest,
  ListBookingsRequest,
} from "../../Generated/bookings_pb";
import { ListDesksRequest } from "../../Generated/desks_pb";
import { ListFloorsRequest } from "../../Generated/floors_pb";
import { GetMeRequest } from "../../Generated/members_pb";
import { useApiClient } from "../Contexts";
import { BookitMember } from "../Models";

const useInitialData = () => {
  const client = useApiClient();

  // Load all office floors
  const [floorsResponse, floorsError, floorsState] = usePromise(async () => {
    const response = await client.listFloors(new ListFloorsRequest());
    const list = response.toObject().floorsList;
    return sortBy(list, "description");
  }, [client]);

  // Load all office desks
  const [desksResponse, desksError, desksState] = usePromise(async () => {
    const request = new ListDesksRequest();
    request.setIncludeDisabled(true);
    const response = await client.listDesks(request);
    return response.toObject().desksList;
  }, [client]);

  const [bookingRangeResponse, bookingRangeError, bookingRangeState] = usePromise(
    () => client.getBookingRange(new GetBookingRangeRequest()),
    [client],
  );

  const [countResponse, countError, countState] = usePromise(async () => {
    const response = await client.getBookingCount(new GetBookingCountRequest());
    return response.toObject();
  }, [client]);

  // Fetch bookings
  const [bookingsResponse, bookingsError, bookingsState] = usePromise(async () => {
    const request = new ListBookingsRequest();
    const response = await client.listBookings(request);
    return response.toObject().bookingsList;
  }, [client]);

  const [memberResponse, memberError, memberState] = usePromise(async () => {
    const response = await client.getMe(new GetMeRequest());
    const member = response.getMe();
    if (!member) {
      throw Error("No member");
    }
    return <BookitMember>{
      member: member.toObject(),
      roles: new Set<string>(response.getRolesList()),
    };
  }, []);

  const error =
    floorsError ?? desksError ?? bookingRangeError ?? countError ?? bookingsError ?? memberError;
  const isLoading = [
    floorsState,
    desksState,
    bookingRangeState,
    countState,
    bookingsState,
    memberState,
  ].some((state) => state === "pending");

  return {
    error,
    isLoading,
    floors: floorsResponse ?? [],
    desks: desksResponse ?? [],
    bookingRange: bookingRangeResponse,
    bookingCount: countResponse,
    bookings: bookingsResponse ?? [],
    member: memberResponse,
  };
};

export default useInitialData;
