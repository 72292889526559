import classnames from "classnames";
import React from "react";

import { ReactComponent as CheckboxIcon } from "../../assets/svgs/Check-24px.svg";
import { ReactComponent as Logo } from "../../assets/svgs/Knowit-logo-white.svg";
import { ReactComponent as Accounting } from "../../assets/svgs/accounting.svg";
import { ReactComponent as ArrowLeftWhite } from "../../assets/svgs/arrow-left-white.svg";
import { ReactComponent as ArrowRightCircleGreen } from "../../assets/svgs/arrow-right-circle-green.svg";
import { ReactComponent as ArrowRightCircle } from "../../assets/svgs/arrow-right-circle.svg";
import { ReactComponent as ArrowRightWhite } from "../../assets/svgs/arrow-right-white.svg";
import { ReactComponent as ArrowRight } from "../../assets/svgs/arrow-right.svg";
import { ReactComponent as CancelBooking } from "../../assets/svgs/cancel-booking.svg";
import { ReactComponent as ChevronDown } from "../../assets/svgs/chevron-down.svg";
import { ReactComponent as ChevronLeft } from "../../assets/svgs/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/svgs/chevron-right.svg";
import { ReactComponent as Close } from "../../assets/svgs/close-icon.svg";
import { ReactComponent as DesignerIconGreen } from "../../assets/svgs/designer-icon-green.svg";
import { ReactComponent as DesignerIcon } from "../../assets/svgs/designer-icon.svg";
import { ReactComponent as DeveloperIconGreen } from "../../assets/svgs/developer-icon-green.svg";
import { ReactComponent as DeveloperIcon } from "../../assets/svgs/developer-icon.svg";
import { ReactComponent as Gaming } from "../../assets/svgs/gaming.svg";
import { ReactComponent as Hamburger } from "../../assets/svgs/hamburger-icon.svg";
import { ReactComponent as Kitchen } from "../../assets/svgs/kitchen.svg";
import { ReactComponent as Lift } from "../../assets/svgs/lift.svg";
import { ReactComponent as Meeting } from "../../assets/svgs/meeting.svg";
import { ReactComponent as PersonSwitch } from "../../assets/svgs/person-switch.svg";
import { ReactComponent as Person } from "../../assets/svgs/person.svg";
import { ReactComponent as Printer } from "../../assets/svgs/printer.svg";
import { ReactComponent as Rabbit } from "../../assets/svgs/rabbit.svg";
import { ReactComponent as RemoveMyBooking } from "../../assets/svgs/remove-my-booking.svg";
import { ReactComponent as Silent } from "../../assets/svgs/silent.svg";
import { ReactComponent as Spark } from "../../assets/svgs/spark.svg";
import { ReactComponent as Stairs } from "../../assets/svgs/stairs.svg";
import { ReactComponent as SwapDeskNoBackground } from "../../assets/svgs/swap-desk-icon-no-background.svg";
import { ReactComponent as SwapDesk } from "../../assets/svgs/swap-desk-icon.svg";
import { ReactComponent as Toilet } from "../../assets/svgs/toilet.svg";
import { ReactComponent as DashedLines } from "../../assets/svgs/traffic-stripes.svg";
import { ReactComponent as UniversalIconGreen } from "../../assets/svgs/universal-desk-icon-green.svg";
import { ReactComponent as UniversalIcon } from "../../assets/svgs/universal-desk-icon.svg";
import { ReactComponent as Waves } from "../../assets/svgs/waves.svg";
import { ReactComponent as WavingHand } from "../../assets/svgs/waving-hand.svg";

const Svgs = Object.freeze({
  Accounting: <Accounting />,
  Rabbit: <Rabbit />,
  Spark: <Spark />,
  Gaming: <Gaming />,
  Kitchen: <Kitchen />,
  Lift: <Lift />,
  Meeting: <Meeting />,
  Printer: <Printer />,
  Silent: <Silent />,
  Stairs: <Stairs />,
  Toilet: <Toilet />,
  Close: <Close />,
  Logo: <Logo />,
  Hamburger: <Hamburger />,
  ArrowRight: <ArrowRight />,
  ArrowRightWhite: <ArrowRightWhite />,
  ArrowRightCircle: <ArrowRightCircle />,
  ArrowRightCircleGreen: <ArrowRightCircleGreen />,
  ArrowLeftWhite: <ArrowLeftWhite />,
  WavingHand: <WavingHand />,
  DesignerIcon: <DesignerIcon />,
  DeveloperIcon: <DeveloperIcon />,
  UniversalIcon: <UniversalIcon />,
  DesignerIconGreen: <DesignerIconGreen />,
  DeveloperIconGreen: <DeveloperIconGreen />,
  UniversalIconGreen: <UniversalIconGreen />,
  CheckboxIcon: <CheckboxIcon />,
  ChevronRight: <ChevronRight />,
  ChevronDown: <ChevronDown />,
  ChevronLeft: <ChevronLeft />,
  Waves: <Waves />,
  DashedLines: <DashedLines />,
  CancelBooking: <CancelBooking />,
  RemoveBooking: <RemoveMyBooking />,
  SwapDesk: <SwapDesk />,
  SwapDeskNoBackground: <SwapDeskNoBackground />,
  Person: <Person />,
  PersonSwitch: <PersonSwitch />,
});

export type SvgName = keyof typeof Svgs;

interface SvgProps {
  className?: string;
  name: SvgName;
  full?: boolean;
}

const Svg = ({ name, full, className }: SvgProps) => (
  <div
    className={classnames(
      "svg flex items-center justify-center h-full pointer-events-none",
      className,
      {
        "svg-full": full,
      },
    )}
  >
    {Svgs[name]}
  </div>
);

export default Svg;
