import classnames from "classnames";
import sortBy from "lodash/sortBy";
import React, { Dispatch, useMemo } from "react";

import { Svg } from "../Core/Components";
import { useDesks, useMemberBookings } from "../Core/Contexts";
import { Day, formatDate, NativeButtonProps } from "../Core/Utilities";

interface ReservationConfirmationProps {
  selectedDays: Set<Day>;
  toggleDay: Dispatch<Day>;
}

const BookingStepConfirmation = ({
  selectedDays,
  toggleDay,
  ...rest
}: ReservationConfirmationProps & NativeButtonProps) => {
  const displayHeader = "Du har valgt";
  const selected: Day[] = useMemo(() => {
    const selected = [...selectedDays];
    return sortBy(selected, (day) => day.dateAsTimestamp.seconds);
  }, [selectedDays]);

  const { getMemberBooking } = useMemberBookings();
  const { getDeskById } = useDesks();

  function getExistingBookingText(day: Day) {
    const existing = getMemberBooking(day);
    if (!existing) return null;
    const deskNumber = getDeskById(existing.deskId)?.number;
    return (
      <p
        className={classnames("leading-4 ml-6", {
          "text-base": deskNumber !== undefined,
          "text-sm": deskNumber === undefined,
        })}
      >
        {deskNumber ? `Byttet plass fra ${deskNumber}` : "Byttet fra plass uten arbeidspullt"}
      </p>
    );
  }

  return (
    <>
      <h2 className="mb-4 text-sm leading-4 font-bold text-projectVeryLightGray">
        {displayHeader}
      </h2>
      <>
        {selected.map((day: Day) => (
          <button
            className={classnames(
              "flex flex-row flex-initial text-projectVeryLightGray bg-projectGray w-full mb-3 items-center p-4 rounded shadow-md",
            )}
            key={`day-${day.index}`}
            onClick={() => toggleDay(day)}
            {...rest}
          >
            <div className="flex flex-row items-center flex-1">
              <h4 className={"text-sm leading-4 font-bold"}>
                {formatDate(day.date, { month: undefined })}
              </h4>
              {getExistingBookingText(day)}
            </div>

            <div className="ml-4 w-4 h-4">
              <Svg name="CancelBooking" />
            </div>
          </button>
        ))}
      </>
    </>
  );
};

export default BookingStepConfirmation;
